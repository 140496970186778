import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'

import { Modal } from 'react-bootstrap'

const DetailsServices = () => {


    const location = useLocation();
    const navigate = useNavigate()
    const data = location.state
    console.log(data, "data")

    const [show, setShow] = useState(false);
    const [selectedService, setSelectedService] = useState(null)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // if (!localStorage.getItem('CONTIGO_TOKEN_PATIENT')) {
        //     navigate('/login');
        // }
    }, [])


    return (
        // <div className='cu-site cu-dashboard-site'>
        //     <Header />
        //     <main className="cu-dashboard-content">
        //         <Sidebar />
        //         <div className="cu-dashboard-content-area">
        //             <div className="cu-dashboard-breadcrumb">
        //                 <a onClick={() => navigate('/details', { state: data })} style={{ cursor: "pointer" }} className="cu-dashboard-breadcrumb-link">
        //                     <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                         <path d="M7.07 1.45621L1 6.88284L7.07 12.3095M18 6.88284H1.17" stroke="black" stroke-width="1.5"
        //                             stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        //                     </svg>
        //                 </a>
        //                 <h2 className="cu-dashboard-breadcrumb-title">View Service Details</h2>
        //             </div>
        //             <div className='cu-service-area'>
        //                 <div className="row">
        //                     {data?.services.map((e) =>
        //                         <div className="col col-12 col-md-6 col-lg-6 col-xl-4">
        //                             <div className="cu-all-service-card">
        //                                 <div className="cu-all-service-card-img">
        //                                     <img src={e?.image} alt="service" className="img-fluid" />
        //                                 </div>
        //                                 <div className="cu-all-service-card-body">
        //                                     <h2 className="cu-all-service-card-title">{e?.name}</h2>
        //                                     <p className="cu-all-service-card-description">{e?.description}</p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     )}
        //                     {/* <div className="col col-12 col-md-6 col-lg-6 col-xl-4">
        //                         <div className="cu-all-service-card">
        //                             <div className="cu-all-service-card-img">
        //                                 <img src="assets/images/service3.png" alt="service" className="img-fluid" />
        //                             </div>
        //                             <div className="cu-all-service-card-body">
        //                                 <h2 className="cu-all-service-card-title">Perosnal Care</h2>
        //                                 <p className="cu-all-service-card-description">The Nike renew rival 2 returns with a
        //                                     redesigned upper in a more traditional.</p>
        //                             </div>
        //                         </div>
        //                     </div> */}
        //                 </div>
        //             </div>
        //         </div>
        //     </main>
        // </div>
        <div className='cu-site cu-dashboard-site'>
            <Header />
            <main className="cu-dashboard-content">
                <Sidebar />
                <div className="cu-dashboard-content-area">
                    <div className="cu-dashboard-breadcrumb">
                        <a onClick={() => window.history.back()} style={{ cursor: "pointer" }} className="cu-dashboard-breadcrumb-link">
                            <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.07 1.45621L1 6.88284L7.07 12.3095M18 6.88284H1.17" stroke="black" stroke-width="1.5"
                                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </a>
                        <h2 className="cu-dashboard-breadcrumb-title">View Service Details</h2>
                    </div>
                    <div className='cu-service-area'>
                        <div className="row">
                            {data?.services?.length === 0 ? (
                                <div>
                                    <h3 className='text-null-data'>No service data available</h3>
                                </div>

                            ) : (
                                data?.services?.map((e) => (
                                    <div key={e?.id} className="col col-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="cu-all-service-card">
                                            <span style={{ cursor: "pointer" }} onClick={() => {
                                                setSelectedService(e); // Set the selected therapy
                                                handleShow(); // Open the modal
                                            }}>
                                                <div className="cu-all-service-card-img" >

                                                    <img src={e?.image} alt="service" className="img-fluid" />
                                                </div>
                                                <div className="cu-all-service-card-body">
                                                    <h2 className="cu-all-service-card-title">{e?.name.length > 27 ? e?.name.substring(0, 27) + '...' : e?.name}</h2>
                                                    <p className="cu-all-service-card-description">{e?.description.length > 100 ? e?.description.substring(0, 100) + '...' : e?.description}</p>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </main>

            <Modal show={show} onHide={handleClose} dialogClassName="custom-modal" centered>
                <Modal.Body closeButton>
                    <div className="cu-all-service-card-img" >
                        <img src={selectedService?.image} alt="therapy" className="img-fluid" />
                    </div>
                    <div className="cu-all-service-card-body">
                        <h2 className="cu-all-service-card-title-modal">{selectedService?.name}</h2>
                        <p className="">{selectedService?.description}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DetailsServices