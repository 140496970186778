import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { CONTIGO_API_URL } from '../BaseURL';
import axios from 'axios';
import { toast } from 'react-hot-toast';

import { Modal } from 'react-bootstrap';

const InquiryForm = () => {


    useEffect(() => {
        if (!localStorage.getItem('CONTIGO_TOKEN_PATIENT')) {
            navigate('/login');
        }
    }, [])
    const serRef = useRef();
    const location = useLocation()

    useEffect(() => {
        const closeDropdown = (e) => {
            if (serRef.current && !serRef.current.contains(e.target)) {
                setShowList(false);
            }
        }

        document.body.addEventListener('click', closeDropdown);
        return () => document.body.removeEventListener('click', closeDropdown);
    }, [])

    const navigate = useNavigate();
    const { state } = useLocation();
    const [disable, setDisable] = useState(false);
    const [show, setShow] = useState(false);
    const [error, setError] = useState({})
    const [inquiry, setInquiry] = useState({
        first_name: "",
        last_name: "",
        number: "",
        email: "",
        description: "",
        admission: "",
        discharge: "",
        service: "",
        other: ""
    })
    const [showList, setShowList] = useState(false);

    const handleChange = (e) => {
        setInquiry({ ...inquiry, [e.target.name]: e.target.value?.trimStart() })
    }

    const handleSubmit = (e) => {
        e?.preventDefault();
        if (validation()) {
            setDisable(true);
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('first_name', inquiry.first_name)
            bodyFormData.append('last_name', inquiry.last_name)
            bodyFormData.append('phone_no', inquiry.number)
            bodyFormData.append('email', inquiry.email)
            bodyFormData.append('description', inquiry.description)
            bodyFormData.append('discharge_date', inquiry.discharge)
            bodyFormData.append('service_need', inquiry.service !== "other" ? inquiry.service : inquiry.other)
            bodyFormData.append('nursingId', localStorage.getItem('LOCATION_ID') || location?.state?._id)

            axios({
                method: 'post',
                url: `${CONTIGO_API_URL}api/patient-user/add-feedback`,
                data: bodyFormData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('CONTIGO_TOKEN_PATIENT')}`
                }
            }).then((response) => {
                console.log(response.data, "@@")
                if (response.data.success === true) {

                    setShow(true);
                    setInquiry({
                        first_name: "",
                        last_name: "",
                        number: "",
                        email: "",
                        description: "",
                        admission: "",
                        discharge: "",
                        service: "",
                    })
                    setTimeout(() => {
                        window.history.back();
                    }, 2000);
                    setDisable(false)
                } else {
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                console.log(error, "error");
                toast.error(error?.response?.data?.message);
                setDisable(false)
            })

        }
    }

    const validation = () => {
        var isValid = true;
        let err = {};

        const currentDate = new Date();

        if (!inquiry.first_name) {
            isValid = false;
            err['first_name_err'] = "Please enter patient first name"
        }
        if (!inquiry.last_name) {
            isValid = false;
            err['last_name_err'] = "Please enter patient last name"
        }

        // if (!inquiry.admission) {
        //     isValid = false;
        //     err['admission_err'] = "Please select date of admission";
        // } else {
        //     const currentDate = new Date();
        //     currentDate.setHours(0, 0, 0, 0); // Set time to midnight

        //     const selectedAdmissionDate = new Date(inquiry.admission);

        //     if (selectedAdmissionDate < currentDate) {
        //         isValid = false;
        //         err['admission_err'] = "Admission date cannot be a previous date";
        //     }
        // }

        if (!inquiry.discharge) {
            isValid = false;
            err['discharge_err'] = "Please select date of discharge";
        } else {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0); // Set time to midnight

            const selectedDischargeDate = new Date(inquiry.discharge);

            if (selectedDischargeDate < currentDate) {
                isValid = false;
                err['discharge_err'] = "Discharge date cannot be a previous date";
            }
        }

        if (!inquiry.description) {
            isValid = false;
            err['description_err'] = "Please enter profile description"
        }

        if (!inquiry.service) {
            isValid = false;
            err['service_err'] = "Please enter service"
        }

        if (!inquiry.email) {
            isValid = false;
            err['email_err'] = "Please enter email"
        } else if (typeof inquiry.email !== "undefined") {
            let lastAtPos = inquiry.email.lastIndexOf('@');
            let lastDotPos = inquiry.email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && inquiry.email.indexOf('@@') === -1 && lastDotPos > 2 && (inquiry.email.length - lastDotPos) > 2)) {
                isValid = false;
                err["email_err"] = "Email is not valid";
            }
        }

        if (!inquiry.number) {
            isValid = false;
            err['number_err'] = "Please enter mobile number"
        } else {
            const phoneRegex = /^\d{1,2}-\d{10}$/; // Updated regular expression for phone number validation
            if (!phoneRegex.test(inquiry.number)) {
                isValid = false;
                err['number_err'] = "Please enter a valid mobile number";
            }
        }

        setError(err);
        return isValid;
    }

    const handleChangeWithoutNumber = (e) => {
        const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]/g, '');
        setInquiry({ ...inquiry, [e.target.name]: sanitizedValue?.trimStart() })
    }


    return (
        <>
            <div className="cu-site cu-dashboard-site">

                <Header />
                <main className="cu-dashboard-content">
                    <Sidebar />
                    <div className="cu-dashboard-content-area">
                        <div className="cu-dashboard-breadcrumb">
                            <a href="/details" className="cu-dashboard-breadcrumb-link">
                                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.07 1.45621L1 6.88284L7.07 12.3095M18 6.88284H1.17" stroke="black" stroke-width="1.5"
                                        stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </a>
                            <h2 className="cu-dashboard-breadcrumb-title">Inquiry Form</h2>
                        </div>
                        <div className="cu-inquiry-content">
                            <div className="row">
                                <div className="col col-12 col-md-12 col-lg-10 col-xl-6">
                                    <div className="cu-inquiry-form-content">
                                        <form className="row">
                                            <div className="col-6 mb-4">
                                                <label htmlFor="inputNamePatient" className="form-label">First Name of Patient</label>
                                                <input type="text" className="form-control" id="inputNamePatient"
                                                    name='first_name' value={inquiry.first_name} onChange={handleChangeWithoutNumber} />
                                                <div className='error error-message'>{error.first_name_err}</div>
                                            </div>
                                            <div className="col-6 mb-4">
                                                <label htmlFor="inputNamePatient" className="form-label">Last Name of Patient</label>
                                                <input type="text" className="form-control" id="inputNamePatient"
                                                    name='last_name' value={inquiry.last_name} onChange={handleChangeWithoutNumber} />
                                                <div className='error error-message'>{error.last_name_err}</div>
                                            </div>
                                            {/* <div className="col col-12 col-md-6 mb-4">
                                                <label htmlFor="inputDateAdmission" className="form-label">Date of Admission</label>
                                                <input type="date" className="form-control" id="inputDateAdmission"
                                                    name='admission' value={inquiry.admission} onChange={handleChange}
                                                />
                                                <div className='error error-message'>{error.admission_err}</div>
                                            </div> */}
                                            <div className="col col-12 col-md-6 mb-4">
                                                <label htmlFor="inputDischargedate" className="form-label">Discharge date</label>
                                                <input type="date" className="form-control" id="inputDischargedate"
                                                    name='discharge' value={inquiry.discharge} onChange={handleChange}
                                                />
                                                <div className='error error-message'>{error.discharge_err}</div>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputPatientProfiledescription" className="form-label">Patient Profile
                                                    description</label>
                                                <input type="text" className="form-control" id="inputPatientProfiledescription"
                                                    name='description' value={inquiry.description} onChange={(e) => e.target.value.length <= 200 && handleChange(e)}
                                                />
                                                <p style={{ textAlign: "right", margin: 0, fontSize: '14px' }}>{inquiry.description.length}/200</p>
                                                <div className='error error-message'>{error.description_err}</div>
                                            </div>
                                            {/* <div className="col-12 mb-4">
                                                <div style={{ position: 'relative' }}>
                                                    <label htmlFor="inputServices" className="form-label">Services Needed.</label>
                                                    <input type="text" className="form-control" id="inputServices"
                                                        name='service' value={inquiry.service} onChange={handleChange}
                                                        onFocus={() => setShowList(true)} ref={serRef}
                                                    />
                                                    <div className='error error-message '>{error.service_err}</div>
                                                    {showList && <div className='ul_filter'>
                                                        {state.services.map(el =>
                                                            <span onClick={() => { setShowList(false); setInquiry({ ...inquiry, service: el.name }) }}>{el.name}</span>
                                                        )}
                                                    </div>}
                                                </div>
                                            </div> */}
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputServices" className="form-label">Services Needed</label>
                                                {inquiry?.service !== "other" ?
                                                    <select className="form-control" id="inputServices"
                                                        name='service' value={inquiry.service} onChange={handleChange}>
                                                        <option value="">Select Service</option>
                                                        {state.services.map(el =>
                                                            <option value={el.name}>{el.name}</option>
                                                        )}
                                                        <option value="other">Other</option>
                                                    </select> :
                                                    <input type="text" className="form-control" id="inputEmailAddress"
                                                        name='other' value={inquiry.other} onChange={(e) => {
                                                            e.target.value.length === 0 ? setInquiry({ ...inquiry, service: '' }) : e.target.value.length <= 100 && handleChange(e)
                                                        }
                                                        }
                                                    />}
                                                {inquiry.service === "other" && <p style={{ textAlign: "right", margin: 0, fontSize: '14px' }}>{inquiry.other.length}/100</p>}
                                                <div className='error error-message'>{error.service_err}</div>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputEmailAddress" className="form-label">Email Address</label>
                                                <input type="text" className="form-control" id="inputEmailAddress"
                                                    name='email' value={inquiry.email} onChange={handleChange}
                                                />
                                                <div className='error error-message'>{error.email_err}</div>
                                            </div>
                                            <div className="col-12 mb-4">
                                                <label htmlFor="inputEmailAddress" className="form-label">Phone Number</label>
                                                {/* <div className="select-code-group">
                                                    <input type="text" className="form-control" id="inputEmailAddress" />
                                                    <select className="form-control" id="countries">
                                                        <option value="GB" data-capital="London">+1</option>
                                                        <option value="FR" data-capital="Paris">+2</option>
                                                        <option value="IT" data-capital="Rome">+03</option>
                                                        <option value="CN" data-capital="Beijing">+98</option>
                                                        <option value="CN" data-capital="Beijing">+99</option>
                                                        <option value="DE" data-capital="Berlin">+45</option>
                                                        <option value="PL" data-capital="Warsaw">+88</option>
                                                    </select>
                                                </div> */}
                                                <PhoneInput
                                                    country='us'
                                                    inputClass='form-control'
                                                    inputExtraProps={{
                                                        name: "number",
                                                        required: true,
                                                        autoFocus: true
                                                    }}
                                                    placeholder=""
                                                    name="number"
                                                    defaultCountry={"us"}
                                                    value={inquiry.number}
                                                    onChange={(val, countryData) => {
                                                        const numericValue = val.replace(/[^0-9]/g, ""); // Extract numeric value only
                                                        const countryCodePrefix = "+" + countryData.dialCode; // Get country code prefix from countryData

                                                        let formattedValue = numericValue;
                                                        if (numericValue.startsWith(countryData.dialCode)) {
                                                            formattedValue = `${countryData.dialCode}-${numericValue.substring(countryData.dialCode.length)}`;
                                                        }
                                                        console.log(countryCodePrefix)
                                                        setInquiry({ ...inquiry, number: formattedValue });
                                                    }}
                                                />
                                                <div className='error error-message'>{error.number_err}</div>
                                            </div>
                                            <div className="col-12">
                                                <div className="cu-inqury-form-btn">
                                                    <button type="button" className="btn btn-info"
                                                        onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : "Submit"}</button>
                                                    <Link to="/details" className="btn btn-outline-info">Cancel</Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                {/* <!-- Thank you Modal --> */}
                {/* <div className="modal fade cu-thank-you-modal" id="thankYouModal" tabindex="-1" aria-labelledby="thankYouModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content ">
                            <div className="modal-body">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div className="cu-thank-you-content">
                                    <div className="cu-thank-you-logo">
                                        <img src="assets/images/logo.png" alt="Logo" className="img-fluid" />
                                    </div>
                                    <h3 className="cu-thank-you-title">Thank You !!</h3>
                                    <p className="cu-thank-you-description">an email will be sent as a confirmation on your email id.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Modal show={show} onHide={() => setShow(false)} centered>
                    <Modal.Body>
                        <div className="cu-thank-you-content">
                            <div className="cu-thank-you-logo">
                                <img src="assets/images/logo.png" alt="Logo" className="img-fluid" />
                            </div>
                            <h3 className="cu-thank-you-title">Thank You !!</h3>
                            <p className="cu-thank-you-description">an email will be sent as a confirmation on your email id.</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default InquiryForm