import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Header = ({ isLoggedIn, smoothScrollToRef, homeRef, howRef, service, contactRef, aboutRef, handleLogout, value }) => {

    const nav = useNavigate()
    const onClickFooterData = (ref, text) => {
        if (value !== "Home") {
            localStorage.setItem("INPUT_DATA", text)
            nav("/")
        } else {
            smoothScrollToRef(ref)
        }
    }


    return (
        <header className="cu-site-header">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid p-0">
                    <a className="navbar-brand" href="/">
                        <img src="assets/images/logo.png" alt="Logo" className="img-fluid" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icons"></span>
                        <span className="navbar-toggler-icons"></span>
                        <span className="navbar-toggler-icons"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className={value === "Home" ? "nav-link active" : "nav-link"} aria-current="page" to={value !== "Home" ? "/" : ""} onClick={() => smoothScrollToRef(homeRef)}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={value !== "Home" ? "/" : ""} onClick={() => onClickFooterData(aboutRef, "aboutRef")}>About Us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={value !== "Home" ? "/" : ""} onClick={() => onClickFooterData(howRef, "howRef")}>How it Works</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={value !== "Home" ? "/" : ""}
                                    onClick={() => onClickFooterData(service, "service")}>Services</Link>
                            </li>
                            <li className="nav-item">
                                {/* <a className="nav-link" href="#contact_us">Contact US</a> */}
                                <Link className="nav-link" to={value !== "Home" ? "/" : ""} onClick={() => onClickFooterData(contactRef, "contactRef")}>Contact US</Link>
                            </li>
                        </ul>
                    </div>

                    {/* <div className="cu-header-btn">
                                <a href="/login" className="btn btn-info">Login</a>
                                <a href="/register" className="btn btn-primary">Sign Up</a>
                            </div> */}

                    <div className="cu-header-btn" style={{ marginRight: "10px" }}>
                        <Link target='_blank' to={process.env.REACT_APP_NURSING_API_URL} className="btn btn-info">Partner with ContigoU</Link>
                    </div>
                    {isLoggedIn ? (
                        <div className="header-btn">
                            <button type='button' className="btn btn-primary" onClick={handleLogout}>
                                Logout
                            </button>
                            {/* <GoogleLogout className='btn btn-light' clientId={clientId} buttonText={"Logout"} onLogoutSuccess={onSuccess} /> */}
                        </div>
                    ) : (
                        <div className="cu-header-btn">
                            <Link to="/login" className="btn btn-info">Login</Link>
                            <Link to="/register" className="btn btn-primary">Sign Up</Link>
                        </div>
                    )}
                </div>
            </nav>
        </header>
    )
}

export default Header