import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';

const Sidebar = () => {

    const nav = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        localStorage.removeItem('CONTIGO_TOKEN_PATIENT');
        toast.success("Logout Successful..!");
        setTimeout(() => {
            nav('/')
        }, 2000);
    }

    const handleBack = () => {
        nav('/search-result');
        localStorage.removeItem('LOCATION_ID')
    }

    return (
        <>
            <div className="cu-sidebar">
                {localStorage.getItem('CONTIGO_TOKEN_PATIENT') && <>
                    <ul className="cu-sidebar-menu-list list-unstyled">
                        <li className={`cu-sidebar-menu-item ${location.pathname === "/details" || location.pathname === "/inquiry-form" ? "active" : ""}`}>
                            <a onClick={handleBack} style={{ cursor: "pointer" }} className="cu-sidebar-menu-link">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.03366 1.66669H4.45033C2.62533 1.66669 1.66699 2.62502 1.66699 4.44169V6.02502C1.66699 7.84169 2.62533 8.80002 4.44199 8.80002H6.02533C7.84199 8.80002 8.80033 7.84169 8.80033 6.02502V4.44169C8.80866 2.62502 7.85033 1.66669 6.03366 1.66669Z"
                                        fill="#78797A" />
                                    <path opacity="0.4"
                                        d="M15.5585 1.66669H13.9752C12.1585 1.66669 11.2002 2.62502 11.2002 4.44169V6.02502C11.2002 7.84169 12.1585 8.80002 13.9752 8.80002H15.5585C17.3752 8.80002 18.3335 7.84169 18.3335 6.02502V4.44169C18.3335 2.62502 17.3752 1.66669 15.5585 1.66669Z"
                                        fill="white" />
                                    <path
                                        d="M15.5585 11.1917H13.9752C12.1585 11.1917 11.2002 12.15 11.2002 13.9667V15.55C11.2002 17.3667 12.1585 18.325 13.9752 18.325H15.5585C17.3752 18.325 18.3335 17.3667 18.3335 15.55V13.9667C18.3335 12.15 17.3752 11.1917 15.5585 11.1917Z"
                                        fill="#78797A" />
                                    <path opacity="0.4"
                                        d="M6.03366 11.1917H4.45033C2.62533 11.1917 1.66699 12.15 1.66699 13.9667V15.55C1.66699 17.375 2.62533 18.3333 4.44199 18.3333H6.02533C7.84199 18.3333 8.80033 17.375 8.80033 15.5583V13.975C8.80866 12.15 7.85033 11.1917 6.03366 11.1917Z"
                                        fill="white" />
                                </svg>
                                <span>Browse Nursing Home</span>
                            </a>
                        </li>
                        <li className={`cu-sidebar-menu-item ${location.pathname === "/write-review" || location.pathname === '/review' ? "active" : ""}`}>
                            <a href="/write-review" className="cu-sidebar-menu-link">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4"
                                        d="M4.78313 13.3333C4.8748 12.925 4.70813 12.3417 4.41647 12.05L2.39147 10.025C1.75813 9.39166 1.50813 8.71666 1.69147 8.13332C1.88313 7.54999 2.4748 7.14999 3.35813 6.99999L5.95813 6.56666C6.33313 6.49999 6.79147 6.16666 6.96647 5.82499L8.3998 2.94999C8.81647 2.12499 9.38313 1.66666 9.9998 1.66666C10.6165 1.66666 11.1831 2.12499 11.5998 2.94999L13.0331 5.82499C13.1415 6.04166 13.3665 6.24999 13.6081 6.39166L4.63313 15.3667C4.51647 15.4833 4.31647 15.375 4.3498 15.2083L4.78313 13.3333Z"
                                        fill="#78797A" />
                                    <path
                                        d="M15.583 12.0517C15.283 12.3517 15.1163 12.9267 15.2163 13.335L15.7913 15.8433C16.033 16.885 15.883 17.6683 15.3663 18.0433C15.1469 18.1967 14.884 18.2756 14.6163 18.2683C14.1913 18.2683 13.6913 18.11 13.1413 17.785L10.6997 16.335C10.3163 16.11 9.68301 16.11 9.29967 16.335L6.85801 17.785C5.93301 18.3267 5.14134 18.4183 4.63301 18.0433C4.44134 17.9017 4.29967 17.71 4.20801 17.46L14.3413 7.32666C14.7247 6.94333 15.2663 6.76833 15.7913 6.86L16.633 7.00166C17.5163 7.15166 18.108 7.55166 18.2997 8.135C18.483 8.71833 18.233 9.39333 17.5997 10.0267L15.583 12.0517Z"
                                        fill="#78797A" />
                                </svg>
                                <span>Write Review</span>
                            </a>
                        </li>
                    </ul>
                    <a onClick={handleLogout} className="cu-logout-link" style={{ cursor: "pointer" }}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4"
                                d="M7.5 5.99999V13.9917C7.5 16.6667 9.16667 18.3333 11.8333 18.3333H13.9917C16.6583 18.3333 18.325 16.6667 18.325 14V5.99999C18.3333 3.33332 16.6667 1.66666 14 1.66666H11.8333C9.16667 1.66666 7.5 3.33332 7.5 5.99999Z"
                                fill="white" />
                            <path
                                d="M10.3587 6.76666L13.1503 9.55833C13.392 9.8 13.392 10.2 13.1503 10.4417L10.3587 13.2333C10.117 13.475 9.71699 13.475 9.47533 13.2333C9.35909 13.1157 9.29391 12.957 9.29391 12.7917C9.29391 12.6263 9.35909 12.4676 9.47533 12.35L11.2003 10.625H2.29199C1.95033 10.625 1.66699 10.3417 1.66699 10C1.66699 9.65833 1.95033 9.375 2.29199 9.375H11.2003L9.47533 7.65C9.41697 7.59224 9.37071 7.52343 9.33923 7.4476C9.30776 7.37177 9.2917 7.29043 9.29199 7.20833C9.29199 7.05 9.35033 6.89166 9.47533 6.76666C9.71699 6.51666 10.1087 6.51666 10.3587 6.76666Z"
                                fill="white" />
                        </svg>
                        <span>Logout</span>
                    </a>
                </>}
            </div>
        </>
    )
}

export default Sidebar